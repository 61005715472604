import React from 'react';
import cn from 'classnames';
import css from './forms.module.css'

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { SeparatorWave } from "../../components/course/separator-wave/SeparatorWave"
import { validateEmail } from "../../components/utils/validators"
import { Footer } from "../../components/shared/Footer"
import { Separator } from "../../components/course/course-ui-components/SeparatorPadding"


const REASONS = [
  { id: 1, title: 'Formazione Azienda: team e gruppi di sviluppatori', text: 'Sono interessato ad un corso di formazione per la mia azienda. \n\nArgomenti:\nN° partecipanti:  \nCittà:  \nPeriodo:  \nDurata stimata: \n\nInviami le tue tariffe!'},
  { id: 2, title: 'Prossimi corsi/eventi in programma per singoli partecipanti', text: 'Vorrei partecipare ad uno dei prossimi corsi di formazione / eventi. \n\nArgomenti:\nCittà:  \nPeriodo:  \n\nInviami le date e tienimi aggiornato!'},
  { id: 3, title: 'Organizzazione Eventi', text: 'Mi piacerebbe organizzare un evento insieme a te. \n\nTipologia Evento: \nCittà:  \nPeriodo: \nAltre Info: '},
  { id: 4, title: 'Partecipazione come Speaker', text: 'Vorrei partecipassi come speaker. \n\nTipologia Evento:  \nCittà:  \nPeriodo:  \nAltre Info:  '},
  { id: 5, title: 'Altro', text: ''},
]
export default class ContactForm extends React.Component {

  state = {
    name: '',
    email: '',
    subject: -1,
    sending: false,
    message: '',
    dirty: false,
    success: false,
    error: false
  }

  componentDidMount() {
    const {search} = this.props.location;
    const getParam = /(\d+)(?!.*\d)/;
    const currentPage = search !== '' ? Number(search.match(getParam)[0]) : -1;
    const subject = REASONS.find(item => item.id === +currentPage);

    if (currentPage !== -1 ) {
      this.setState({ subject: +currentPage, message: subject.text})
    }
  }

  send = () => {
    const subject = REASONS.find(item => item.id === +this.state.subject);

    var template_params = {
      "from_name": this.state.name,
      "subject": subject.title,
      "message_html": `
        Inviato da: ${this.state.name} (${this.state.email})<br /><br />
        Oggetto: ${subject.title}<br /><br />
        ${this.state.message}
      ` ,
    }

    var service_id = "default_service";
    var template_id = "template_EINXui09";
    this.setState({ error: false, sending: true })

    window.emailjs.send(service_id, template_id, template_params, 'user_U93fAB8CUZq39WdYExova')
      .then((response) => {
        this.setState({ success: true, error: false, sending: false })
      }, (error) => {
        console.log('FAILED...', error);
        this.setState({ error: true, sending: false })
      });
  }

  onChange = (event) => {
    const prop = event.target.name
    this.setState({
      [prop]: event.target.value,
      error: false,
      dirty: true
    })
  }

  onChangeSubject = (event) => {
    const item = REASONS.find(item => item.id === +event.target.value);
    if (item) {
      this.setState({ message: item.text, subject: item.id, error: false, dirty: true})
    } else {
      this.setState({ subject: -1, error: false, dirty: true})
    }

  }
  render() {
    const { error, dirty, success, message, email, name, sending, subject } = this.state;
    const nameIsValid = name.length > 2;
    const emailIsValid = validateEmail(email);
    const subjectIsValid = subject > -1;
    const messageIsValid = message.length > 5;
    const valid = !error && nameIsValid && emailIsValid && messageIsValid && subjectIsValid;

    return (
      <Layout>
        <SEO title="Form Contatti" />
        <SeparatorWave />

        <div className="row">
          <div className="col-xs">
            <div className="container-big center">
              <div className="text-box-title">CONTATTAMI</div>
              <br/>
              <input
                className={cn('form-control text round', css.contact_input, { 'isValid': nameIsValid, 'isInvalid': !nameIsValid && dirty})}
                type="text"
                placeholder="Il tuo nome / società"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
                disabled={success}
              />
              <br/>
              <br/>

              <input
                className={cn('form-control text round', css.contact_input, { 'isValid': emailIsValid, 'isInvalid': !emailIsValid && dirty})}
                type="text"
                placeholder="La tua email"
                name="email"
                value={this.state.email}
                onChange={this.onChange}
                disabled={success}
              />
              <br/>
              <br/>

              <select
                className={cn('form-control text round', css.contact_input, { 'isValid': subjectIsValid, 'isInvalid': !subjectIsValid && dirty})}
                onChange={this.onChangeSubject}
                name="subject"
                value={subject}
                disabled={success}
              >
                <option value="-1">Motivo del contatto</option>
                {
                  REASONS.map(item => {
                    return <option value={item.id} key={item.id}>{item.title}</option>
                  })
                }
              </select>

              <br/>
              <br/>
              <textarea
                className={cn('form-control textarea round', css.contact_textarea, { 'isValid': messageIsValid, 'isInvalid': !messageIsValid && dirty})}
                rows="8"
                value={this.state.message}
                onChange={this.onChange}
                name="message"
                disabled={success}
                placeholder="Scrivi un messaggio"
              />

              <br/>
              <br/>
              {
                success ? null : <button
                  disabled={!valid || sending}
                  style={{ opacity: !valid || sending ? 0.5 : 1 }}
                  className="button button1"
                  onClick={this.send}>INVIA</button>
              }

              {
                success ?
                  <div style={{ color: 'green' }}>
                    Richiesta inviata con successo.
                  </div> : null
              }

              {
                error ?
                  <div style={{ color: 'red' }}>
                    Errore. Contatta <a href="mailto: hello@fabiobiondi.io">hello@fabiobiondi.io</a>
                  </div> : null
              }

            </div>

          </div>
        </div>

        <Separator size="xl"/>
        <Footer></Footer>
      </Layout>
    )
  }
}
